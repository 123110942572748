import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from './store';

import http from "./http.js";
Vue.prototype.axios = http;

import Toasted from "vue-toasted";
Vue.use(Toasted);

import "material-icons/iconfont/material-icons.css";

import VueBarcode from '@chenfengyuan/vue-barcode';

Vue.component(VueBarcode.name, VueBarcode);

import Toast from './mixins/toast.js';
Vue.prototype.toast = Toast;

import Paginate from 'vuejs-paginate'
Vue.component('paginate', Paginate)

import VueScrollTo from 'vue-scrollto';
Vue.use(VueScrollTo);

import Auth from "./mixins/auth.js";

import loadingOverlay from "./components/pages/loadingOverlay.vue";
Vue.component("loader", loadingOverlay);

import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full';
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

import vSelect from 'vue-select';
Vue.component('v-select', vSelect);

import "vue-select/dist/vue-select.css";

import SelectDropdown from './components/components/SelectDropdown.vue';
Vue.component('select-dropdown', SelectDropdown);


Vue.prototype.$appVersion = process.env.VUE_APP_VERSION ? process.env.VUE_APP_VERSION : '1.0.0';

import moment from 'moment-timezone';
moment.tz.setDefault(process.env.VUE_APP_DEFAULT_TIMEZONE ? process.env.VUE_APP_DEFAULT_TIMEZONE : 'Australia/Canberra');
Vue.prototype.moment = moment;

Vue.mixin({
    methods: {
        auth: Auth
    }
});

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app");
